<template>
  <div class="home">
    <Header></Header>
    <div class="news-HImg">
      <img src="@/assets/images/1551929445.jpg" />
    </div>
    <div class="news-minimenu">
      <div class="container">
        <div class="form-group">
          <div class="input-search">
            <button type="submit" class="input-search-btn butbg" ho>
              <b-icon
                icon="search"
                width="12"
                height="12"
                @click="searchSolution()"
              ></b-icon>
            </button>
            <input
              type="text"
              class="form-control"
              name="searchword"
              placeholder="请输入搜索关键词"
              v-model="keyword"
            />
          </div>
        </div>
        <div class="form-group">
          <div
            class="search-res"
            v-for="(info, index) in solutionList"
            :key="index"
          >
            <p>
              <a
                class="search-res-title"
                :href="SearchResUrl + 'solutiondetails?id=' + info.soluId"
                >{{ info.soluTitleLv1 }}</a
              >
            </p>
            <a
              class="search-res-link"
              :href="SearchResUrl + '?id=' + info.soluId"
              >{{ SearchResUrl }}solutiondetails?id={{ info.soluId }}</a
            >
            <p>
              {{ info.soluTitleLv2 }}
            </p>
            <hr />
          </div>
        </div>
        <div class="form-group">&nbsp;</div>
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>
<script>
import Header from "@/views/components/Header.vue";
import Footer from "@/views/components/Footer.vue";
export default {
  name: "SolutionDetails",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      keyword: "",
      //解决方案信息
      solutionList: [],
      show: false,
      count: 5,
      timer: null,
    };
  },
  mounted() {
    var _this = this;
    _this.keyword = this.$route.query.keyword; //获取解决方案ID
    //获取解决方案信息
    _this.searchSolution();
  },
  methods: {
    //跳转解决方案中心
    clickJump() {
      const timejump = 5;
      this.count = timejump;
      this.show = true;
      this.timer = setInterval(() => {
        if (this.count > 0 && this.count <= timejump) {
          this.count--;
        } else {
          this.show = true;
          clearInterval(this.timer);
          this.timer = null;
          //跳转的页面写在此处
          this.$router.push({
            path: "/solution",
          });
        }
      }, 1000);
    },
    //搜索解决方案
    searchSolution() {
      var _this = this;
      var keyword = _this.keyword;
      if (keyword != null && keyword != "") {
        _this.$axios
          .get(
            "http://localhost/dev-api/system/solution/search?pageNum=1&pageSize=10&keyword=" +
              keyword
          )
          .then(function (response) {
            if (response.data.code == 200) {
              if (response.data.rows) {
                _this.solutionList = response.data.rows;
              } else {
                //解决方案不存在，跳转解决方案中心
                //_this.clickJump()
              }
            }
          });
      }
    },
  },
  filters: {
    filtersNewsTitle(val) {
      if (val != null && val != "") {
        return val.substr(0, 12) + "......";
      } else {
        return "";
      }
    },
  },
  watch: {
    //监听路由改变
    $route(to) {
      var _this = this;
      //获取解决方案类型
      _this.soluId = to.query.id;
      //获取解决方案信息
      _this.searchSolution();
    },
  },
};
</script>
<style scoped>
.home {
  background-color: #f2f2f2;
  color: #2a333c;
}

.news-header {
  height: 120px;
  background: #00c9a1;
  text-align: center;
}

.news-header h2 {
  line-height: 120px;
  color: #37474f;
}

.news-minimenu {
  width: 100%;
  background: #ffffff;
}

.news-minimenu svg {
  margin-top: -5px;
}

.input-search {
  position: relative;
  padding: 3rem 0 1rem 0;
}

.input-search .input-search-btn {
  position: absolute;
  top: 3.4rem;
  right: 0.5rem;
  border: 0px;
  background-color: rgba(0, 0, 0, 0);
}

.input-search .form-control {
  border-radius: 2rem;
  background: #f3f7f9;
  border-color: #e4eaec;
  color: #76838f;
  font-size: 14px;
}

.input-search .form-control:focus {
  border: 1px solid #00c9a1;
  box-shadow: none;
}

a {
  text-decoration: none !important;
}
.search-res-title {
  font-size: 1.2rem;
  color: #37474f;
  text-decoration: none;
}

.search-res-title:hover {
  color: #00c9a1;
}

.search-res-link {
  font-size: 1rem;
  color: #89bceb;
}

.search-res hr {
  margin-top: 20px;
}

.form-group {
  margin-bottom: 0rem;
}

p {
  margin-top: 0;
  margin-bottom: 0rem;
}
</style>
